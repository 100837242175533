$white: #ffffff;

$light: #f4f4f4;
$dark: #4b4a49;
// $primary: #673434;
// $secondary: #cb3437;
// $info: #3b8ab9;
// $success: #5a981f;
// $warning: #febc5e;
// $danger: #ea1264;
// Above pallet generated by https://huemint.com/bootstrap-basic/

$comment-section-bg: $light;

// $header-bg: $light;
$header-bg: #f0f0f0;

$body-wrapper-background-color: $white;

@import "~bootstrap/scss/bootstrap.scss";

blockquote {
  @extend .blockquote;
  @extend .bg-light;
  @extend .rounded;
  // border-left: 1px solid blue;
  border-left: 1px solid $info;
  max-width: 90%;

  // copy from Notion.so-ish
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 1em;
  padding: 0.25em;
  color: inherit;
  fill: inherit;
  // display: flex;
}
// :root {
//   --body-background: #f2f2f2;
//   --body-color: #444;
//   --heading-color: black;
//   --action-color: #d64045;
// }

main {
  // @extend .body;
  background: var(--body-background);
  color: var(--body-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  // margin: 0px 12px 24px 10px;
  margin: 1em 1em 3em 1em;
  // font-size: 108%;
  // line-height: 1.5;
}

footer {
  @extend .small;
  color: $white;
  background-color: $dark;
  // margin: 24px auto 0 auto;
  // padding: 5px 0 5px 5px;
}

body {
  background-color: $dark;
  a { text-decoration: none; }
  a:hover { text-decoration: underline; }
}

.hide {
  display: none;
}

div.body-wrapper {
  background-color: $body-wrapper-background-color;
}

.description {
  // @extend .bg-light;
  @extend .lead;
  padding: 0.25rem;
  margin: 0.25rem;
  // background: var(--bs-indigo);
}

// a {
//   color: var(--action-color);
//   text-decoration: underline;
//   text-decoration-color: #ffb088;
// }

.heading-base {
  background-color: $header-bg;
  padding-left: 0.25rem;
  @extend .rounded-start; // @extend .rounded-3;
  // border-bottom: 1px solid $dark;
}

h1 {
  @extend .heading-base;
  margin: 1rem 0 2rem;
  // font-weight: 900;
  // font-size: 2.5rem;
  // color: var(--heading-color);
  line-height: 1.2;
  // border-bottom: 2px solid $dark;
  border-top: 2px solid $dark;
  // border-left: 2px solid $dark;
  &.headline {
    text-align: center;
    border: none;
    margin: 0.25rem;
    padding: 0.5rem;
    background-color: $body-wrapper-background-color;
  }
}

h2 {
  margin: 1rem 0 0.25em auto;
  @extend .heading-base;
  // border-bottom: 1px solid $dark;
}

h3 {
  @extend .heading-base;
}
.thing h3 {
  margin: 2rem 0 0.25em 0.25rem;
}

h4 {
  @extend .heading-base;
}
.thing h4 {
  margin: 2rem 0 0.25em 0.5rem;
}

h5 {
  @extend .heading-base;
}
.thing h5 {
  margin: 2rem 0 0.25em 0.75rem;
  border-left: 1px dashed $secondary;
}

h6 {
  @extend .heading-base;
}
.thing h6 {
  margin: 2rem 0 0.25em 1.0rem;
}

table {
  @extend .table;
}

dt {
  // @extend h4;
  font-size: 1.25rem;
  font-weight: 700;
}
dd {
  @extend .blockquote;
  @extend .bg-light;
  // border-left: 1px solid blue;
  border-left: 1px solid $info;
  max-width: 90%;
  margin-left: 1em;
  padding-left: 1rem;
  display: inline;
}

.breadcrumbs {
  // @extend .float-right;
  margin: auto;
  h6 {
    margin: auto;
    border: none;
  }
}

img {
  @extend .img-fluid;
}

.content a.image img {
  @extend .img-thumbnail;
}

.sbs-list {
  .row {
    margin-bottom: 1em;
    &.light {
      background-color: $white;
    }
    &.dark {
      background-color: $light;
    }
  }


}

// body > header {
//   margin: 1rem;
//   text-align: center;
// }

// body > header img {
//   display: inline-block;
//   width: 400px;
//   max-width: 100%;
// }

// .bg-secondary.bg-gradient ul {
//   margin: 2rem 0;
//   padding: 0;
//   list-style-type: none;
//   display: flex;
//   justify-content: center;
//   gap: 1.5rem;
//   font-size: 1.3rem;
//   font-weight: bold;
// }

// body > nav a {
//   text-decoration: none;
// }

main {
  // margin: 2rem auto 4rem;
  // max-width: 65rem;
  // min-height: calc(100vh - 200px);
  // padding: 25px 25px 50px;
  // background: white;
  // box-shadow: 2px 3px 3px #ddd;
  // border-radius: 3px;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

.footnotes {
  border-top: 1px solid $dark;
}

footer {
//   text-align: center;
//   margin-bottom: 4rem;
//   font-size: 1em;
  .license {
    font-size: 75%;
  }
}

// hr {
//   border: none;
//   border-top: 2px dotted #bbb;
//   margin: 3rem 0;
// }

// // code blocks

.highlight {
  @extend .rounded-3;
  @extent .code-snippet;
  pre.code {
    // background-color: ivory;
  }
}

.thing img {
  @extend .rounded-3;
}

// Disqus comment styling


div.comment-section {
  background-color: $comment-section-bg;
  margin-top: 2em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
